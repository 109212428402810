/* .picture {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}

.image {
  flex: 0 1 calc(25% - 10px);
  margin-bottom: 20px;
  overflow: hidden;
  box-sizing: border-box;
} */

.search {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 50px;
  border: 3px;
  padding: 8px;
  margin: auto;
  background-color: rgb(27, 212, 245);
}

input {
  display: inline-block;
  height: 30px;
  margin: auto;
  width: 30%;
  border: 3px black;
  border-radius: 15px;
}

select {
  display: inline-block;
  height: 30px;
  width: 10%;
  margin: auto;
  padding: 5px;
}

#container {
  height: 100%;
  width: 1200px;
  margin: auto;
}

.masonry {
  column-width: 180px;
  column-gap: 10px;
}

.item {
  width: 100%;
}

img {
  width: 100%;
  padding: 3px;
  animation: slideIn 1.5s ease-in-out forwards;
}

@keyframes slideIn {
  0% {
    transform: translateX(500px) scale(.2);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}